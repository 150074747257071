<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="品牌" prop="logoId">
            <a-select placeholder="请选择" v-model="rowData.logoId">
              <a-select-option :value="item.id" v-for="(item, index) in brandList" :key="index">{{
                item.brandName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="商品名称" prop="name">
            <a-input v-model="rowData.name" placeholder="商品名称"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="商品型号" prop="specStr">
            <a-input v-model="rowData.specStr" placeholder="商品型号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="商品价格" prop="price">
            <a-input-number
              style="width: 100%"
              v-model="rowData.price"
              :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
              placeholder="商品价格"
            ></a-input-number>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="商品图片" prop="image">
            <QiniuUpload type="image" :value.sync="rowData.image"></QiniuUpload>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="是否上架" prop="flagUp">
            <a-switch v-model="rowData.flagUp" checked-children="上架" un-checked-children="下架" default-checked />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editDealerProOrderGoods,
  selectByIdDealerProOrderGoods,
  addDealerProOrderGoods,
} from '../api/DealerProOrderGoodsApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        logoId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        specStr: [{ required: true, message: '请输入商品型号', trigger: 'blur' }],
        price: [{ required: true, message: '请输入商品价格', trigger: 'blur' }],
        image: [{ required: true, message: '请上传商品图片', trigger: 'blur' }],
      },
      brandList: [],
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      if(handle == 'add') {
        this.$set(this.rowData, 'flagUp', true)
      }
      if (handle == 'edit') {
        selectByIdDealerProOrderGoods(row.id).then((res) => {
          this.rowData = res.body
        })
      }
      this.axios.get(`/api/dealer/company/dealerCompanyInfo/getDealerCompanyInfo`).then(res => {
        this.brandList = res.body.brandLogos
      })
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const res =
          this.handle === 'add'
            ? await addDealerProOrderGoods(this.rowData)
            : await editDealerProOrderGoods(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
